<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1300"
      scroll="clip"
    >
      <div class="modal-card " style="width: auto;">
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <div
            style="max-width: 200px"
            class="flex flex-row flex-wrap justify-between w-full items-center my-2.5"
          >
            <span class="daily-transaction-history-print-out-font font-bold"
              >Tanggal :
              {{ printOutData ? printOutData.letterhead.printDate : "-" }}</span
            >
            <span class="daily-transaction-history-print-out-font font-bold"
              >Batch :
              {{ printOutData ? printOutData.letterhead.batch : "-" }}</span
            >
          </div>

          <div class="flex flex-col justify-start  w-full items-start ">
            <span
              class="daily-transaction-history-print-out-font font-bold mt-1.5"
              >Keterangan :
              {{
                printOutData ? printOutData.letterhead.description : "-"
              }}</span
            >
          </div>
          <b-table
            class="mt-3"
            narrowed
            v-if="printOutData"
            :data="printOutData.tableData"
          >
            <template v-slot:empty>
              <div class="flex justify-center items-center p-3">
                <span class="daily-transaction-history-print-out-font">
                  No Data Found
                </span>
              </div>
            </template>
            <b-table-column field="" label="No.Urut">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="daily-transaction-history-print-out-font">{{
                  props.index + 1
                }}</span>
              </template>
            </b-table-column>
            <b-table-column field="" label="Keterangan">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="daily-transaction-history-print-out-font">{{
                  props.row.description ? props.row.description : "-"
                }}</span>
              </template>
            </b-table-column>

            <b-table-column field="" label="Rekening Debet">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span
                  class="daily-transaction-history-print-out-font"
                  v-if="props.row.mutationPosition === 'D'"
                  >{{
                    props.row.accountNumber ? props.row.accountNumber : "-"
                  }}</span
                >
              </template>
            </b-table-column>
            <b-table-column field="" label="Rekening Credit">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span
                  class="daily-transaction-history-print-out-font"
                  v-if="props.row.mutationPosition === 'C'"
                  >{{
                    props.row.accountNumber ? props.row.accountNumber : "-"
                  }}</span
                >
              </template>
            </b-table-column>

            <b-table-column field="" label="Nama">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span class="daily-transaction-history-print-out-font">{{
                  props.row.accountName ? props.row.accountName : "-"
                }}</span>
              </template>
            </b-table-column>

            <b-table-column field="" label="Nominal Debet">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span
                  class="daily-transaction-history-print-out-font"
                  v-if="props.row.mutationPosition === 'D'"
                  >{{
                    props.row.nominal >= 0
                      ? props.row.nominal
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : formatMinusAccountingVersion(props.row.nominal)
                  }}</span
                >
              </template>
            </b-table-column>

            <b-table-column field="" label="Nominal Credit">
              <template v-slot:header="{ column }">
                <span class="daily-transaction-history-print-out-font">{{
                  column.label
                }}</span>
              </template>
              <template v-slot="props">
                <span
                  class="daily-transaction-history-print-out-font"
                  v-if="props.row.mutationPosition === 'C'"
                  >{{
                    props.row.nominal >= 0
                      ? props.row.nominal
                          .toFixed(2)
                          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
                      : formatMinusAccountingVersion(props.row.nominal)
                  }}</span
                >
              </template>
            </b-table-column>
          </b-table>
          <div class="w-full flex justify-between items-end flex-col mt-4 ">
            <span
              class="daily-transaction-history-print-out-font font-bold mr-7"
              >Total Debet :
              {{
                totalDb
                  ? totalDb.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : "-"
              }}</span
            >
            <span
              class="daily-transaction-history-print-out-font font-bold mr-7"
              >Total Credit :
              {{
                totalCr
                  ? totalCr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
                  : "-"
              }}</span
            >
          </div>
          <div class="mt-20  flex justify-start align-start w-full flex-col">
            <div>
              <span class="font-bold daily-transaction-history-print-out-font ">
                ( Maker )
              </span>
              <span class="font-bold daily-transaction-history-print-out-font ">
                ( Checker )
              </span>
              <span class="font-bold daily-transaction-history-print-out-font">
                ( Signer )
              </span>
            </div>
            <div>
              <span class="font-bold daily-transaction-history-print-out-font">
                Print By : {{ printOutData ? printOutData.printBy : "-" }}
              </span>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <div class="flex flex-row justify-between w-full">
            <b-button @click="closePreview" label="Batal" />
            <b-button
              :loading="isLoading"
              @click="onPressPrint"
              label="Print "
              type="is-link"
            />
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import Mixins from "@/service/MixinsFuntions.js";

export default {
  name: "DailyTransactionHistoryPrintOut",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },
  mixins: [Mixins],
  computed: {
    totalDb() {
      if (!this.printOutData) {
        return 0;
      }
      if (this.printOutData.tableData.length === 0) {
        return 0;
      }

      let val = 0;
      for (let index = 0; index < this.printOutData.tableData.length; index++) {
        if (this.printOutData.tableData[index].mutationPosition === "D") {
          val = val + this.printOutData.tableData[index].nominal;
        }
      }
      return val;
    },
    totalCr() {
      if (!this.printOutData) {
        return 0;
      }
      if (this.printOutData.tableData.length === 0) {
        return 0;
      }

      let val = 0;
      for (let index = 0; index < this.printOutData.tableData.length; index++) {
        if (this.printOutData.tableData[index].mutationPosition === "C") {
          val = val + this.printOutData.tableData[index].nominal;
        }
      }
      return val;
    },
  },
  methods: {
    closePreview() {
      this.showModal = false;
      this.$nextTick(() => {
        this.printOutData = null;
      });
    },

    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `Laporan_Transaksi_Harian.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;
      // New Promise-based usage:
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.autoPrint();
          const hiddFrame = document.createElement("iframe");
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
        });
    },
  },
};
</script>

<style>
.daily-transaction-history-print-out-font {
  font-size: 8px;
}
</style>
