<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card h-screen" style="width: auto;">
        <header class="modal-card-head">
          <span class="text-base">Preview</span>
        </header>
        <div id="output-canvas" class="modal-card-body w-full p-3">
          <div class="flex justify-center w-full items-center flex-col p-0">
            <div class="w-full flex justify-between items-start flex-row">
              <span class="daily-transaction-history-print-out-font font-bold"
                >HIK PARAHYANGAN</span
              >
              <span class="daily-transaction-history-print-out-font font-bold"
                >Tanggal :
                {{
                  printOutData ? printOutData.letterhead.printDate : "-"
                }}</span
              >
            </div>
            <div class="w-full mt-1 flex justify-between items-start flex-row">
              <span
                class="daily-transaction-history-print-out-font font-bold"
                >{{
                  printOutData ? printOutData.letterhead.officeName : "-"
                }}</span
              >
              <span class="font-bold"></span>
            </div>
            <div class="w-full mt-1 flex justify-center items-center flex-col">
              <span class="daily-transaction-history-print-out-font font-bold"
                >JURNAL TRANSAKSI HARIAN</span
              >
              <span
                class="daily-transaction-history-print-out-font font-bold mt-1"
                >SUKSES PER TANGGAL :
                {{
                  printOutData ? printOutData.letterhead.filteredDate : "-"
                }}</span
              >
            </div>
            <b-table
              class="mt-3"
              narrowed
              v-if="printOutData"
              :data="printOutData.tableData"
            >
              <template v-slot:empty>
                <div class="flex justify-center items-center p-3">
                  <span class="daily-transaction-history-print-out-font">
                    No Data Found
                  </span>
                </div>
              </template>
              <b-table-column centered field="noUrut" label="Urut">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.index + 1
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="officeCode" label="KD. Cabang">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.officeCode
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="journalDate" label="TGL.Jurnal">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.journalDate
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="referenceNumber" label="No Trans">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.referenceNumber
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="transactionCode" label="KD.TRANS">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.transactionCode ? props.row.transactionCode : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="debtAccountNumber"
                label="No.Debet"
              >
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.debtAccountNumber
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="creditAccountNumber"
                label="No.Kredit"
              >
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.creditAccountNumber
                      ? props.row.creditAccountNumber
                      : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="description" label="KETERANGAN">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.description
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="linkModule" label="Dokumen">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.linkModule ? props.row.linkModule : "-"
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="debtNominal" label="Nominal. DB">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.debtNominal
                      ? convertDecimalCurrency(props.row.debtNominal)
                      : props.row.debtNominal
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column
                centered
                field="creditNominal"
                label="Nominal. CR"
              >
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.creditNominal
                      ? convertDecimalCurrency(props.row.creditNominal)
                      : props.row.creditNominal
                  }}</span>
                </template>
              </b-table-column>
              <b-table-column centered field="createdBy" label="Batch">
                <template v-slot:header="{ column }">
                  <span class="daily-transaction-history-print-out-font">{{
                    column.label
                  }}</span>
                </template>
                <template v-slot="props">
                  <span class="daily-transaction-history-print-out-font">{{
                    props.row.createdByUserCode
                      ? props.row.createdByUserCode
                      : "-"
                  }}</span>
                </template>
              </b-table-column>
              <template #footer>
                <th style="border-right:0px">
                  <span class="daily-transaction-history-print-out-font"
                    >Total</span
                  >
                  <br />
                  <span class="daily-transaction-history-print-out-font"
                    >Selisih</span
                  >
                </th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px"></th>
                <th style="border-left:0px;border-right:0px">
                  <span class="daily-transaction-history-print-out-font">
                    {{ totalDb ? convertDecimalCurrency(totalDb) : totalDb }}
                  </span>
                  <br />
                  <span class="daily-transaction-history-print-out-font">
                    {{
                      difference
                        ? convertDecimalCurrency(difference)
                        : difference
                    }}
                  </span>
                </th>
                <th style="border-left:0px;border-right:0px">
                  <span class="daily-transaction-history-print-out-font">
                    {{ totalCr ? convertDecimalCurrency(totalCr) : totalCr }}
                  </span>
                </th>
                <th style="border-left:0px;border-right:0px"></th>
              </template>
            </b-table>
            <div
              class="mt-20 ml-4 flex justify-start align-start w-full flex-col"
            >
              <div>
                <span
                  class="font-bold daily-transaction-history-print-out-font mr-2"
                >
                  ( Maker )
                </span>
                <span
                  class="font-bold daily-transaction-history-print-out-font mr-2"
                >
                  ( Checker )
                </span>
                <span
                  class="font-bold daily-transaction-history-print-out-font"
                >
                  ( Signer )
                </span>
              </div>
              <div>
                <span
                  class="font-bold daily-transaction-history-print-out-font"
                >
                  Print By : {{ printOutData ? printOutData.printBy : "-" }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            :disabled="isLoading"
            @click="onPressPrint"
            label="Print Out"
            type="is-link"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
export default {
  name: "DailyTransactionHistoryListPdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },
  computed: {
    difference() {
      let val = parseFloat(this.totalDb) - parseFloat(this.totalCr);
      return val;
    },
    totalDb() {
      if (!this.printOutData) {
        return 0;
      }
      if (this.printOutData.tableData.length === 0) {
        return 0;
      }
      let val = 0;
      let index = 0;
      while (this.printOutData.tableData.length !== index + 1) {
        val = val + this.printOutData.tableData[index].debtNominal;
        index++;
      }
      return val;
    },
    totalCr() {
      if (!this.printOutData) {
        return 0;
      }
      if (this.printOutData.tableData.length === 0) {
        return 0;
      }
      let val = 0;
      let index = 0;
      while (this.printOutData.tableData.length !== index + 1) {
        val = val + this.printOutData.tableData[index].creditNominal;
        index++;
      }
      return val;
    },
  },
  methods: {
    closePreview() {
      this.showModal = false;
      this.printOutData = null;
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        margin: 0.1,
        filename: `Laporan_Transaksi_Harian_${this.printOutData.letterhead.filteredDate}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .set(opt)
        .from(element)
        .save();
    },
  },
};
</script>

<style>
.daily-transaction-history-print-out-font {
  font-size: 8px;
}
</style>
