<template>
  <div>
    <b-modal
      :on-cancel="closePreview"
      v-model="showModal"
      :width="1000"
      scroll="clip"
    >
      <div class="modal-card h-screen" style="width: auto;">
        <header class="modal-card-head">
          <span class="text-base">Preview</span>
        </header>
        <div
          id="output-canvas"
          class="card"
          style="width: auto;border-radius:0px"
        >
          <div
            style="width:90%"
            class="mt-3 mb-2 ml-2  bg-white flex justify-start items-center flex-col"
          >
            <div class="flex flex-row flex-wrap w-full items-center">
              <table class="table table-custom-report">
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Tanggal Transaksi
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.journalDate }}
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Batch
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.createdByUserCode }}
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    No.trans
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.referenceNumber }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Kode Transaksi
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.journalCode }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Rekening Debet
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.debtAccountNumber }}
                    {{ printOutData.data.debtAccountNumberName }}
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    SBB db
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.debtCoaCode }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Rekening Kredit
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.creditAccountNumber }}
                    {{ printOutData.data.creditAccountNumberName }}
                  </td>
                  <!-- <td></td><td></td><td></td>           -->
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    SBB cr
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.creditCoaCode }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Dokumen
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.linkModule }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Nominal
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{
                      printOutData.data.creditNominal
                        ? convertDecimalCurrency(
                            printOutData.data.creditNominal
                          )
                        : printOutData.data.creditNominal
                    }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Keterangan
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.description }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Sts Transaksi
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    {{ printOutData.data.statusCode }}
                  </td>
                </tr>
                <tr>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Sts Cetak
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-bold"
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td></td><td></td><td></td>    -->
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Pokok
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    0.00
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td></td><td></td><td></td>    -->
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Margin
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    0.00
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td></td><td></td><td></td>    -->
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Inputer
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    -
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td></td><td></td><td></td>    -->
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    Auhtorizer
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    :
                  </td>
                  <td
                    class="daily-transaction-journal-single-single-font font-semibold"
                  >
                    -
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <b-button @click="closePreview" label="Batal" />
          <b-button
            :loading="isLoading"
            :disabled="isLoading"
            @click="printDailyTransactionHistorySingle(printOutData)"
            label="Print Out"
            type="is-link"
          />
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import Mixins from "@/service/MixinsFuntions.js";

export default {
  name: "DailyTransactionJournalSinglePdf",
  data() {
    return {
      showModal: false,
      isLoading: false,
      printOutData: null,
    };
  },
  mixins: [Mixins],
  computed: {},
  methods: {
    closePreview() {
      this.showModal = false;
      this.$nextTick(() => {
        this.printOutData = null;
      });
    },
    onOpenPreview(payload) {
      this.showModal = true;
      this.printOutData = payload;
    },
    async onPressPrint() {
      this.isLoading = true;
      var element = document.getElementById("output-canvas");
      var opt = {
        filename: `Journal_Transaksi_Harian_${this.printOutData.data.referenceNumber}.pdf`,
        image: { type: "jpeg", quality: 10 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      await this.simpleWait(1000);
      this.isLoading = false;

      // New Promise-based usage:
      html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.autoPrint();
          const hiddFrame = document.createElement("iframe");
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
        });
    },
  },
};
</script>

<style>
.daily-transaction-journal-single-single-font {
  font-size: 11px;
}
</style>
