var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"on-cancel":_vm.closePreview,"width":1300,"scroll":"clip"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"modal-card ",staticStyle:{"width":"auto"}},[_c('div',{staticClass:"modal-card-body w-full p-3",attrs:{"id":"output-canvas"}},[_c('div',{staticClass:"flex flex-row flex-wrap justify-between w-full items-center my-2.5",staticStyle:{"max-width":"200px"}},[_c('span',{staticClass:"daily-transaction-history-print-out-font font-bold"},[_vm._v("Tanggal : "+_vm._s(_vm.printOutData ? _vm.printOutData.letterhead.printDate : "-"))]),_c('span',{staticClass:"daily-transaction-history-print-out-font font-bold"},[_vm._v("Batch : "+_vm._s(_vm.printOutData ? _vm.printOutData.letterhead.batch : "-"))])]),_c('div',{staticClass:"flex flex-col justify-start  w-full items-start "},[_c('span',{staticClass:"daily-transaction-history-print-out-font font-bold mt-1.5"},[_vm._v("Keterangan : "+_vm._s(_vm.printOutData ? _vm.printOutData.letterhead.description : "-"))])]),(_vm.printOutData)?_c('b-table',{staticClass:"mt-3",attrs:{"narrowed":"","data":_vm.printOutData.tableData},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"flex justify-center items-center p-3"},[_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(" No Data Found ")])])]},proxy:true}],null,false,661157559)},[_c('b-table-column',{attrs:{"field":"","label":"No.Urut"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.index + 1))])]}}],null,false,3152492569)}),_c('b-table-column',{attrs:{"field":"","label":"Keterangan"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.description ? props.row.description : "-"))])]}}],null,false,3017712981)}),_c('b-table-column',{attrs:{"field":"","label":"Rekening Debet"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [(props.row.mutationPosition === 'D')?_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.accountNumber ? props.row.accountNumber : "-"))]):_vm._e()]}}],null,false,1114465215)}),_c('b-table-column',{attrs:{"field":"","label":"Rekening Credit"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [(props.row.mutationPosition === 'C')?_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.accountNumber ? props.row.accountNumber : "-"))]):_vm._e()]}}],null,false,881595288)}),_c('b-table-column',{attrs:{"field":"","label":"Nama"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.accountName ? props.row.accountName : "-"))])]}}],null,false,2220513685)}),_c('b-table-column',{attrs:{"field":"","label":"Nominal Debet"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [(props.row.mutationPosition === 'D')?_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.nominal >= 0 ? props.row.nominal .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : _vm.formatMinusAccountingVersion(props.row.nominal)))]):_vm._e()]}}],null,false,1709981486)}),_c('b-table-column',{attrs:{"field":"","label":"Nominal Credit"},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
return [_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(column.label))])]}},{key:"default",fn:function(props){return [(props.row.mutationPosition === 'C')?_c('span',{staticClass:"daily-transaction-history-print-out-font"},[_vm._v(_vm._s(props.row.nominal >= 0 ? props.row.nominal .toFixed(2) .replace(/\d(?=(\d{3})+\.)/g, "$&,") : _vm.formatMinusAccountingVersion(props.row.nominal)))]):_vm._e()]}}],null,false,2341817417)})],1):_vm._e(),_c('div',{staticClass:"w-full flex justify-between items-end flex-col mt-4 "},[_c('span',{staticClass:"daily-transaction-history-print-out-font font-bold mr-7"},[_vm._v("Total Debet : "+_vm._s(_vm.totalDb ? _vm.totalDb.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : "-"))]),_c('span',{staticClass:"daily-transaction-history-print-out-font font-bold mr-7"},[_vm._v("Total Credit : "+_vm._s(_vm.totalCr ? _vm.totalCr.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") : "-"))])]),_c('div',{staticClass:"mt-20  flex justify-start align-start w-full flex-col"},[_c('div',[_c('span',{staticClass:"font-bold daily-transaction-history-print-out-font "},[_vm._v(" ( Maker ) ")]),_c('span',{staticClass:"font-bold daily-transaction-history-print-out-font "},[_vm._v(" ( Checker ) ")]),_c('span',{staticClass:"font-bold daily-transaction-history-print-out-font"},[_vm._v(" ( Signer ) ")])]),_c('div',[_c('span',{staticClass:"font-bold daily-transaction-history-print-out-font"},[_vm._v(" Print By : "+_vm._s(_vm.printOutData ? _vm.printOutData.printBy : "-")+" ")])])])],1),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"flex flex-row justify-between w-full"},[_c('b-button',{attrs:{"label":"Batal"},on:{"click":_vm.closePreview}}),_c('b-button',{attrs:{"loading":_vm.isLoading,"label":"Print ","type":"is-link"},on:{"click":_vm.onPressPrint}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }