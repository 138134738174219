import { jsPDF } from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import moment from "moment";
import DailyTransactionHistoryListPdf from "@/components/page/daily-transaction-history-list-pdf";
import DailyTransactionHistorySinglePdf from "@/components/page/daily-transaction-journal-single-pdf";
import DailyTransactionHistoryPrintOut from "@/components/page/daily-transaction-history-print-out";
import axios from "axios";
export default {
  name: "DailyTransactionHistory",
  computed: {
    officeCodeSession() {
      let getSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).officeCode;
      return getSession;
    },
    userCodeSession() {
      let getUserSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      ).roleId;
      return getUserSession;
    },
  },
  components: {
    DailyTransactionHistoryListPdf,
    DailyTransactionHistorySinglePdf,
    DailyTransactionHistoryPrintOut,
  },
  data() {
    return {
      isLoading: false,
      property: {
        listElement: {
          dailyTransaction: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        modal: {
          showModalPreview: false,
        },
        animation: {
          submitLoading: false,
          cetakSlip: {
            isLoading: false,
          },
          isDownloadingFile: false,
        },
      },

      dataForm: {
        userId: "",
        totalTransaction: "",
        totalDb: "",
        totalCr: "",
        name: "",
        accountNumber: "",
        description: "",
        printDate: "",
        batch: "",
        printBy: "",
        idUser: "",
        idOffice: "",
        userName: "",
        userCode: "",
        journalDate: "",
        date: "",
        officeName: "",
        exportContent: "",
        exportToExcel: {
          data: [],
          header: [],
        },
        previewData: "",
      },
      options: {
        users: [],
        cabangs: [],
        eksport: [],
      },
      table: {
        headers: {},
        data: {
          dailyTransaction: [],
          print: [],
        },
      },
    };
  },

  methods: {
    Moment: moment,
    exportPdf() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      this.$refs.dailyTransactionHistoryListPdfRef.onOpenPreview({
        tableData: this.table.data.dailyTransaction,
        letterhead: {
          printDate: `${moment().format("DD-MM-YYYY")} ${moment().format(
            "HH:mm:ss"
          )}`,
          officeName: this.dataForm.officeName,
          filteredDate: this.dataForm.journalDate
            ? this.dataForm.journalDate
            : `${moment().format("DD-MM-YYYY")} `,
        },
        printBy: `${getUserPayloadFromSession.userName} ${moment().format(
          "DD-MM-YYYY"
        )} ${moment().format("HH:mm:ss")}`,
      });
    },

    async onDownloadFile(fileType) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      const payload = {
        fileType: fileType,
        idUser: this.dataForm.idUser,
        officeCode: this.dataForm.idOffice,
        journalDate: this.dataForm.journalDate,
      };
      this.property.animation.isDownloadingFile = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_SAVE_RESPONSE_DATA",
          endPoint: "report",
          reqUrl: "report/jurnal-report-v2/files",
          payload: payload,
        });
        if (fileType === "pdf") {
          this.printOutWindow(resp.data);
          return;
        }
        var a = document.createElement("a"),
          url = URL.createObjectURL(resp.data);
        a.href = url;
        a.download = `Jurnal_Transaksi_Harian.${
          fileType === "xls" ? "xlsx" : "pdf"
        }`;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
        // eslint-disable-next-line no-empty
      } catch (error) {
      } finally {
        this.property.animation.isDownloadingFile = false;
      }
    },

    handleCatchErrorJournalReport(error) {
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async getJournalReport() {
      this.table.data.dailyTransaction = [];
      this.property.listElement.dailyTransaction.downloading = true;
      this.dataForm.exportContent = "";
      this.dataForm.exportToExcel.data = [];
      this.dataForm.exportToExcel.header = [];
      const timeout = 500;

      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_LIST_DATA",
            endPoint: "report",
            reqUrl: "report/jurnal-report-v2",
            payload: {
              idUser: this.dataForm.idUser,
              officeCode: this.dataForm.idOffice,
              journalDate: this.dataForm.journalDate,
            },
          });
          console.log(resp);
          setTimeout(() => {
            if (resp.data.code === "SUCCESS") {
              this.table.data.dailyTransaction = resp.data.data.detail;
              this.dataForm.totalTransaction = resp.data.data.totalTransaction;
            } else {
              this.property.listElement.dailyTransaction.message =
                resp.data.message;
            }
          }, timeout);
        } catch (error) {
          setTimeout(() => {
            this.handleCatchErrorJournalReport(error);
          }, timeout);
        } finally {
          setTimeout(() => {
            this.property.listElement.dailyTransaction.downloading = false;
          }, timeout);
        }
      }
    },
    async handleResetsearch() {
      this.dataForm.idOffice = "";
      this.dataForm.idUser = "";
      this.dataForm.journalDate = "";
      this.getJournalReport();
    },
    helperGetUSerDataFromSession() {
      const getUserPayloadFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getUserPayloadFromSession);
      this.dataForm.userName = getUserPayloadFromSession.userName;
      this.dataForm.idUser = axios.defaults.headers.common["x-user-id"];
      this.dataForm.userCode = getUserPayloadFromSession.userCode;
      this.dataForm.officeName = getUserPayloadFromSession.officeName;
      // this.dataForm.idOffice = getUserPayloadFromSession.officeId;
    },
    async handlePreviewData(props) {
      if (props.row.multiTransactionDetail === null) {
        const getUserPayloadFromSession = JSON.parse(
          this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
        );
        this.$refs.dailyTransactionHistorySinglePdfRef.onOpenPreview({
          data: props.row,
          printBy: `${getUserPayloadFromSession.userName} ${moment().format(
            "DD-MM-YYYY"
          )} ${moment().format("HH:mm:ss")}`,
        });
      } else {
        const getUserPayloadFromSession = JSON.parse(
          this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
        );
        this.$refs.dailyTransactionHistoryPrintOutRef.onOpenPreview({
          tableData: props.row.multiTransactionDetail,
          letterhead: {
            printDate: this.dataForm.journalDate,
            batch: props.row.batch,
            description: props.row.description,
          },
          printBy: `${getUserPayloadFromSession.userName} ${moment().format(
            "DD-MM-YYYY"
          )} ${moment().format("HH:mm:ss")}`,
        });
        // const data = {
        //   noUrut: props.row.noUrut,
        //   journalCode: props.row.journalCode,
        //   journalDate: props.row.journalDate,
        //   description: props.row.description,
        //   descriptionDetail: props.row.descriptionDetail,
        //   statusId: props.row.statusId,
        //   statusCode: props.row.statusCode,
        //   statusName: props.row.statusName,
        //   referenceNumber: props.row.referenceNumber,
        //   debtAccountNumber: props.row.debtAccountNumber,
        //   debtAccountNumberName: props.row.debtAccountNumberName,
        //   creditAccountNumber: props.row.creditAccountNumber,
        //   creditAccountNumberName: props.row.creditAccountNumberName,
        //   debtNominal: props.row.debtNominal,
        //   creditNominal: props.row.creditNominal,
        //   officeCode: props.row.officeCode,
        //   createdBy: props.row.createdBy,
        //   createdDate: props.row.createdDate,
        //   linkModule: props.row.linkModule,
        //   debtCoaCode: props.row.debtCoaCode,
        //   creditCoaCode: props.row.creditCoaCode,
        //   createdByUserCode: props.row.createdByUserCode,
        //   createdByUserName: props.row.createdByUserName,
        //   authorizedByUserName: props.row.authorizedByUserName,
        //   transactionCode: props.row.transactionCode,
        //   batch: props.row.batch,
        //   multiTransactionDetail: props.row.multiTransactionDetail,
        // };
        // this.isLoading = true;
        // try {
        //   const resp = await this.$store.dispatch({
        //     type: "POST_DATA_SAVE_FILE",
        //     endPoint: "report",
        //     reqUrl: "report/jurnal-report-v2/multi-transaction",
        //     payload: data,
        //   });
        //   var a = document.createElement("a"),
        //     url = URL.createObjectURL(resp.data);
        //   a.href = url;
        //   a.download = `Laporan_Transaksi_Harian.pdf`;
        //   document.body.appendChild(a);
        //   a.click();
        //   setTimeout(function() {
        //     document.body.removeChild(a);
        //     window.URL.revokeObjectURL(url);
        //   }, 0);
        //   // eslint-disable-next-line no-empty
        // } catch (error) {
        // } finally {
        //   this.isLoading = false;
        // }
      }
    },
    async helperGetCabangList() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            url: "office",
            params: {
              officeName: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.cabangs = [{ value: "", text: "-- Semua Cabang --" }];
          resp.data.data.content.map((index) => {
            const value = index.officeCode;
            const text = `${index.officeCode} - ${index.officeName}`;
            this.options.cabangs.push({ text, value });
            this.helperGetUserDataForReference();
          });
        }
      } catch (error) {}
    },
    async helperGetUserDataForReference() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_USER_MANAGEMENT",
          {
            url: "v2/user/active-user",
            params: {
              page: 0,
              officeCode: this.dataForm.idOffice,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          this.options.users = [{ value: "", text: "-- Pilih --" }];
          resp.data.data.content.map((index) => {
            const value = index.userId;
            const text = `${index.userCode} - ${index.profileSurename}`;
            this.options.users.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async cetakSlipTrx() {
      this.property.animation.cetakSlip.isLoading = true;
      let pdf = new jsPDF("potrait", "mm", "a4");
      setTimeout(() => {
        html2canvas(document.querySelector("#table-trx"), {
          imageTimeout: 2000,
          useCORS: true,
        }).then((canvas) => {
          document.getElementById("table-trx").appendChild(canvas);
          let img = canvas.toDataURL("image/png");
          pdf.addImage(img, "PNG", 10, 9);
          pdf.autoPrint();
          const hiddFrame = document.createElement("iframe");
          hiddFrame.style.position = "fixed";
          hiddFrame.style.width = "1px";
          hiddFrame.style.height = "1px";
          hiddFrame.style.opacity = "0.01";
          const isSafari = /^((?!chrome|android).)*safari/i.test(
            window.navigator.userAgent
          );
          if (isSafari) {
            // fallback in safari
            hiddFrame.onload = () => {
              try {
                hiddFrame.contentWindow.document.execCommand(
                  "print",
                  false,
                  null
                );
              } catch (e) {
                hiddFrame.contentWindow.print();
              }
            };
          }
          hiddFrame.src = pdf.output("bloburl");
          document.body.appendChild(hiddFrame);
          document.getElementById("table-trx").innerHTML = "";
          this.property.animation.cetakSlip.isLoading = false;
          this.property.modal.showModalPreview = false;
        });
      }, 200);
    },
    async getSystemDate() {
      this.dataForm.journalDate = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;

      this.getJournalReport();
    },
    setDefaultOfficeCodeValue() {
      const getUserDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );
      console.log(getUserDataFromSession);
      this.dataForm.idOffice = getUserDataFromSession.officeCode;
    },
  },
  mounted() {
    moment.locale("ID");
    this.helperGetUSerDataFromSession();
    this.helperGetUserDataForReference();
    this.helperGetCabangList();
    this.getSystemDate();
    this.setDefaultOfficeCodeValue();
  },
};
